<template>
    <div class="l-padded">
        <h3 class="t-title">
            {{ $t('shared.assets') }}

            ({{ trackers.length }})
        </h3>

        <div class="l-padded-y panels">
            <div>
                <div class="form-label">
                    {{ $t('byBattery') }}
                </div>

                <ApexChart
                    type="bar"
                    width="100%"
                    height="300"
                    :options="chartByBatteryOptions"
                    :series="chartByBatterySeries"
                />
            </div>

            <div>
                <div class="form-label">
                    {{ $t('byActivity') }}
                </div>

                <ApexChart
                    type="donut"
                    width="100%"
                    height="300"
                    :options="chartByActiveOptions"
                    :series="chartByActiveSeries"
                />
            </div>

            <div>
                <div class="form-label">
                    {{ $t('byType') }}
                </div>

                <div class="type-card-row">
                    <AssetTypeCountCard
                        v-for="(count, assetType) in assetsByType()"
                        :key="`asset-card-${assetType}`"
                        :icon="assetTypeIcons[assetType]"
                        :count="count"
                    />
                </div>
            </div>
        </div>

        <h3 class="t-title">
            {{ $t('latestUpdates') }}
        </h3>

        <MeasurementsUpdatesTable />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ApexChart from 'vue-apexcharts'

import AssetTypeCountCard from '../AssetTypeCountCard'
import MeasurementsUpdatesTable from '../MeasurementsUpdatesTable'

export default {
    name: 'DashboardOverviewView',
    components: {
        ApexChart,
        AssetTypeCountCard,
        MeasurementsUpdatesTable,
    },
    data() {
        return {
            chartByBatterySeries: [],
            chartByBatteryOptions: {
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: 'top',
                        },
                    },
                },
                xaxis: {
                    title: {
                        text: this.$t('batteryPercentage'),
                    },
                    axisBorder: {
                        show: true,
                    },
                    axisTicks: {
                        show: false,
                    },
                },
                yaxis: {
                    title: {
                        text: this.$t('numberOfAssets'),
                    },
                    axisBorder: {
                        show: true,
                    },
                },
                dataLabels: {
                    enabled: true,
                    offsetY: 5,
                    style: {
                        colors: ['#fff'],
                    },
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#00A4E1',
                        shadeTo: 'dark',
                        shadeIntensity: 0.75,
                    },
                },
                fill: {
                    opacity: 0.9,
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                legend: {
                    position: 'bottom',
                },
                tooltip: {
                    enabled: false,
                },
            },
            chartByActiveSeries: [],
            chartByActiveOptions: {
                labels: [this.$t('active'), this.$t('inactive')],
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    label: this.$t('total'),
                                    color: '#333',
                                },
                            },
                        },
                    },
                },
                dataLabels: {
                    dropShadow: {
                        enabled: false,
                    },
                },
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#00A4E1',
                        shadeTo: 'dark',
                        shadeIntensity: 0.75,
                    },
                },
                fill: {
                    opacity: 0.9,
                },
                legend: {
                    position: 'bottom',
                },
            },
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapGetters('authentication', ['batteryThreshold']),
        ...mapGetters('tracker', [
            'activeAssetsCount',
            'assetTypeIcons',
            'assetsByType',
            'inactiveAssetsCount',
            'lostAssetsCount',
            'lowBatteryAssetsCount',
        ]),
        assetsByBattery() {
            const { low, high } = this.batteryValueBounds
            const batteryValues = this.trackers.map(
                tracker => tracker.asset_details.sensor_data?.battery?.value
            )

            return {
                low: batteryValues.filter(value => value <= low).length,
                medium: batteryValues.filter(
                    value => value > low && value < high
                ).length,
                high: batteryValues.filter(value => value >= high).length,
            }
        },
        batteryValueBounds() {
            const low = this.batteryThreshold
            const high = Math.round((100 - low) * 0.75)

            return { low, high }
        },
    },
    created() {
        this.chartByBatteryOptions.labels = [
            this.$t('low', [this.batteryValueBounds.low]),
            this.$t('medium', [
                this.batteryValueBounds.low,
                this.batteryValueBounds.high,
            ]),
            this.$t('high', [this.batteryValueBounds.high]),
        ]
    },
    mounted() {
        this.chartByBatterySeries = [
            { data: Object.values(this.assetsByBattery) },
        ]

        this.chartByActiveSeries = [
            this.activeAssetsCount,
            this.inactiveAssetsCount,
        ]
    },
}
</script>

<i18n>
{
    "en": {
        "active": "Active",
        "batteryPercentage": "Battery percentage",
        "byActivity": "By activity",
        "byBattery": "By battery value",
        "byType": "By type",
        "high": "{0}% or more",
        "inactive": "Inactive",
        "latestUpdates": "Latest updates",
        "leftLocation": "Left location",
        "locations": "Locations",
        "low": "{0}% or less",
        "lowBattery": "Low battery",
        "medium": "{0}% to {1}%",
        "numberOfAssets": "Number of assets",
        "total": "Total"
    },
    "de": {
        "active": "Aktiv",
        "batteryPercentage": "Batteriezustand",
        "byActivity": "Nach Aktivierung",
        "byBattery": "Nach Batteriezustand",
        "byType": "Nach Typ",
        "high": "{0}% oder mehr",
        "inactive": "Inaktiv",
        "latestUpdates": "Neueste Updates",
        "leftLocation": "Standort verlassen",
        "locations": "Standorte",
        "low": "{0}% oder weniger",
        "lowBattery": "Niedriger Batteriestand",
        "medium": "{0}% bis {1}%",
        "numberOfAssets": "Anzahl Assets",
        "total": "Total"
    },
    "it": {
        "active": "Attivo",
        "batteryPercentage": "percentuale della batteria",
        "byActivity": "Per attività",
        "byBattery": "Per valore della batteria",
        "byType": "Per tipo",
        "high": "{0}% o più",
        "inactive": "Inattivo",
        "latestUpdates": "Ultimi aggiornamenti",
        "leftLocation": "Fuori locazione",
        "locations": "Locazione",
        "low": "{0}% o meno",
        "lowBattery": "Basso Batteria",
        "medium": "{0}% di {1}%",
        "numberOfAssets": "Numero di assets",
        "total": "Totalmente"
    }
}
</i18n>

<style lang="scss" scoped>
.panels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > div {
        margin: 0.5rem auto;
        flex-grow: 1;
        flex-basis: 0;
        min-width: 300px;
    }

    .form-label {
        text-align: center;
        font-weight: 700;
    }
}

.type-card-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -4px;
    padding: 8px 0 16px;

    div {
        margin: 8px 16px 8px 0;
    }
}
</style>
